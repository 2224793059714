import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useBoolean } from '../../hooks/use-boolean';

import styles from './TypingText.module.scss';

type TypingTextProps = {
  className?: string;
  children: string;
  duration: number;
  delay?: number;
};

export const TypingText: FC<TypingTextProps> = (props) => {
  const { className, children, duration, delay = 0 } = props;

  const [text, setText] = useState('');
  const { value: isActive, setTrue: enable, setFalse: disable } = useBoolean(false);

  const rafHandleRef = useRef(0);

  const caret = useMemo(() => {
    return <span className={styles.caret} />;
  }, []);

  useEffect(() => {
    setText('');
    disable();

    const timeout = setTimeout(() => {
      const startTimestamp = Date.now();

      const rafCallback = () => {
        const elapsedTime = Date.now() - startTimestamp;

        setText(children.slice(0, Math.floor((elapsedTime / duration) * children.length)));

        if (elapsedTime < duration) {
          rafHandleRef.current = requestAnimationFrame(rafCallback);

          enable();
        } else {
          disable();
        }
      };

      rafHandleRef.current = requestAnimationFrame(rafCallback);
    }, delay);

    return () => {
      cancelAnimationFrame(rafHandleRef.current);
      clearTimeout(timeout);
    };
  }, [children, delay, disable, duration, enable]);

  return (
    <span className={classNames(styles.root, { [styles.isActive]: isActive }, className)}>
      <span className={styles.fake}>
        {children}
        {caret}
      </span>

      <span className={styles.real}>
        {text}
        {caret}
      </span>
    </span>
  );
};
