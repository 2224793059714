import { Box, Container } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CardIcon } from '../icons/CardIcon';
import { LockIcon } from '../icons/LockIcon';
import { MastercardIcon, ApplePayIcon, BitcoinIcon, VisaIcon } from '../icons/paySystems';

import styles from './Options.module.scss';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

interface PaymentSystem {
  title: string;
  icon: ReactNode;
}

interface Currency {
  title: string;
  color: string;
  code: string;
}

type List = Currency[] | PaymentSystem[];

const paymentSystems: PaymentSystem[] = [
  {
    title: 'MasterCard',
    icon: <MastercardIcon />,
  },
  {
    title: 'Apple Pay',
    icon: <ApplePayIcon />,
  },
  // {
  //   title: 'Bitcoin',
  //   icon: <BitcoinIcon />,
  // },
  {
    title: 'Visa',
    icon: <VisaIcon />,
  },
];

const currencies: Currency[] = [
  {
    code: 'rub',
    title: '₽',
    color: '#0466C8',
  },
  {
    code: 'eur',
    title: '€',
    color: '#9D4EDD',
  },
  {
    code: 'usd',
    title: '$',
    color: '#16DB65',
  },
  {
    code: 'kz',
    title: '₸',
    color: '#dbb416',
  },
];

const DEFAULT_DELAY = 2000;

const ANIMATION_SPEED = 1000;

export const Options: FC = () => {
  const { t } = useTranslation('common');
  const [paymentSystem, setPaymentSystem] = useState<PaymentSystem | null>(null);
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [initialDelay, setInitialDelay] = useState<number>(DEFAULT_DELAY);

  const autoTypeAmount = () => {
    const rand = Math.floor(Math.random() * 1000).toString();
    let value = '';
    for (let i = 0; i < rand.length; i++) {
      setTimeout(() => {
        value += rand[i];
        setAmount(value);
      }, i * 100);
    }
  };

  return (
    <Box className={styles.optionsWrapper}>
      <Container maxWidth={'md'} sx={{ margin: '56px auto' }}>
        <div className={styles.optionsTitle}>
          <p>
            <Trans i18nKey="options.title">
              Easy to use <span>interface</span>.
            </Trans>
          </p>
        </div>
        <ul className={styles.optionsList}>
          <li>
            <div className={styles.optionsListInner}>
              <Swiper
                direction={'vertical'}
                autoplay={{
                  delay: DEFAULT_DELAY,
                  disableOnInteraction: false,
                }}
                speed={ANIMATION_SPEED}
                modules={[Autoplay]}
                className={styles.paymentList}
                height={224}
                loop={true}
                slidesPerView={3}
                spaceBetween={16}
                onSlideChange={(swiper) => {
                  setInitialDelay(0);
                  setPaymentSystem(paymentSystems[swiper.realIndex + 1] ?? paymentSystems[0]);
                }}
              >
                {[...paymentSystems].map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={[
                      styles.paymentListItem,
                      paymentSystem === item ? styles.paymentListItemActive : '',
                    ].join(' ')}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </li>

          <li>
            <div className={styles.optionsListInner}>
              <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
              {/*<ul className={[styles.currencyList].join(' ')}>
                {[...currencies, ...currencies, ...currencies, ...currencies].map((item, index) => (
                  <li
                    key={index}
                    className={[currency === item ? styles.currencyListItemActive : '', 'currency_' + item.code].join(' ')}
                  >
                    <span style={{backgroundColor: item.color}}>{item.title}</span>
                  </li>
                ))}
              </ul>*/}

              <Swiper
                direction={'vertical'}
                autoplay={{
                  delay: DEFAULT_DELAY + initialDelay,
                  disableOnInteraction: false,
                }}
                speed={ANIMATION_SPEED}
                modules={[Autoplay]}
                className={styles.currencyList}
                height={224}
                loop={true}
                slidesPerView={3}
                spaceBetween={16}
                onSlideChange={(swiper) => {
                  setCurrency(currencies[swiper.realIndex + 1] ?? currencies[0]);
                  autoTypeAmount();
                }}
              >
                {[...currencies].map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={[styles.currencyListItem, currency === item ? styles.currencyListItemActive : ''].join(
                      ' '
                    )}
                  >
                    <span style={{ backgroundColor: item.color }}>{item.title}</span>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </li>

          <li>
            <div className={styles.optionsListInner}>
              <div className={styles.cardBlock}>
                <CardIcon />
                <div className={styles.cardBlockLock}>
                  <LockIcon />
                  <span>Service Level Agreement</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </Container>
    </Box>
  );
};
