import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from './types';

export const LockIcon: FC<IconProps> = ({ width = 32, height = 36 }) => {
  return (
    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.2856 15.8626H27.2499V5.8626C27.2499 2.70635 24.6919 0.148315 21.5356 0.148315H10.4642C7.30797 0.148315 4.74993 2.70635 4.74993 5.8626V15.8626H1.71422C0.924037 15.8626 0.285645 16.501 0.285645 17.2912V34.434C0.285645 35.2242 0.924037 35.8626 1.71422 35.8626H30.2856C31.0758 35.8626 31.7142 35.2242 31.7142 34.434V17.2912C31.7142 16.501 31.0758 15.8626 30.2856 15.8626ZM7.96422 5.8626C7.96422 4.48314 9.08475 3.3626 10.4642 3.3626H21.5356C22.9151 3.3626 24.0356 4.48314 24.0356 5.8626V15.8626H7.96422V5.8626ZM28.4999 32.6483H3.49993V19.0769H28.4999V32.6483ZM14.7499 26.443V28.809C14.7499 29.0055 14.9106 29.1662 15.1071 29.1662H16.8928C17.0892 29.1662 17.2499 29.0055 17.2499 28.809V26.443C17.6184 26.1784 17.8935 25.8037 18.0355 25.3728C18.1775 24.942 18.1792 24.4772 18.0402 24.0453C17.9012 23.6135 17.6288 23.2369 17.2622 22.9697C16.8955 22.7026 16.4536 22.5586 15.9999 22.5586C15.5463 22.5586 15.1043 22.7026 14.7377 22.9697C14.371 23.2369 14.0986 23.6135 13.9597 24.0453C13.8207 24.4772 13.8223 24.942 13.9643 25.3728C14.1064 25.8037 14.3814 26.1784 14.7499 26.443V26.443Z" fill="#24292D"/>
    </svg>
  );
};


