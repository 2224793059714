import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';

import { ScrollDownIcon } from '../icons';
import { TypingText } from '../TypingText';

import styles from './Hero.module.scss';

type HeaderBarProps = {};

export const Hero: FC<HeaderBarProps> = () => {
  const { t } = useTranslation('common');

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const isSmallSize = useMediaQuery('(max-width:960px)');

  const slides = [
    {
      title: t('hero.list.payments.title'),
      body: t('hero.list.payments.body'),
    },
    {
      title: t('hero.list.locations.title'),
      body: t('hero.list.locations.body'),
    },
    {
      title: t('hero.list.sla.title'),
      body: t('hero.list.sla.body'),
    },
  ];

  useEffect(() => {
    let timer = 0;
    let step = 5000;

    setInterval(() => {
      timer += step;

      switch (timer) {
        case 5000:
          setActiveSlide(1);
          break;

        case 10000:
          setActiveSlide(2);
          break;

        case 15000:
          setActiveSlide(0);
          break;
      }

      if (timer === 15000) timer = 0;
    }, 5000);
  }, []);

  return (
    <Box className={styles.heroWrapper}>
      <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <div className={styles.heroTitle}>
            <Trans i18nKey="hero.title">
              Smart and secure payment solutions <span>for your business.</span>
            </Trans>
          </div>
          <Grid container spacing={4} columns={6} sx={{ marginTop: '2rem' }}>
            {(isSmallSize ? [slides[activeSlide]] : slides).map(({ title, body }, index) => (
              <Grid key={index} item md={2} className={[styles.heroSection, styles.typeWriting].join(' ')}>
                <div>
                  <h4>{isSmallSize ? <TypingText duration={1000}>{title}</TypingText> : title}</h4>
                </div>
                <br />
                <div>
                  <p>
                    {isSmallSize ? (
                      <TypingText duration={2000} delay={2000}>
                        {body}
                      </TypingText>
                    ) : (
                      body
                    )}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <div className={styles.scrollDown}>
        <ScrollDownIcon />
      </div>
    </Box>
  );
};
