import { Box, Button, Container, Grid, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { GpayIcon, VisaIcon, MastercardAltIcon, ApplePayAltIcon, BitcoinAltIcon } from '../icons/paySystems';
import { DigitalIcon, FinanceIcon, GamingIcon, MarketplaceIcon, ServiceIcon, ShopIcon } from '../icons/services';

import styles from './Cards.module.scss';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/scrollbar';

type HeaderBarProps = {};

export const Cards: FC<HeaderBarProps> = () => {
  const { locale } = useRouter();
  const isSmallSize = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation('common');

  const lkBasePath = `/lk/${locale}`;
  return (
    <Box className={styles.cardsWrapper}>
      <Container maxWidth={'md'} sx={{ margin: '56px auto' }}>
        <div className={styles.cardsTitle}>
          <p>
            <Trans i18nKey="cards.title">
              Find <span>special features</span> for your industry.
            </Trans>
          </p>
        </div>
      </Container>

      <Swiper
        spaceBetween={20}
        centeredSlides={false}
        slidesPerView={isSmallSize ? 1 : 4}
        grabCursor={true}
        initialSlide={isSmallSize ? 1 : 2}
        autoplay={true}
        className={styles.cardsListSlider}
        modules={[Scrollbar]}
        scrollbar={{
          draggable: true,
          hide: false,
        }}
      >
        <SwiperSlide className={styles.cardsListSliderSlide}>
          <div className={styles.cardsListItem}>
            <ShopIcon />
            <h3>{t('cards.block_1.title')}</h3>
            <p>{t('cards.block_1.text')}</p>
            <a href={`${lkBasePath}/registration`}>
              <Button>{t('cards.cta')}</Button>
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide className={styles.cardsListSliderSlide}>
          <div className={styles.cardsListItem}>
            <ServiceIcon />
            <h3>{t('cards.block_2.title')}</h3>
            <p>{t('cards.block_2.text')}</p>
            <a href={`${lkBasePath}/registration`}>
              <Button>{t('cards.cta')}</Button>
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide className={styles.cardsListSliderSlide}>
          <div className={styles.cardsListItem}>
            <MarketplaceIcon />
            <h3>{t('cards.block_3.title')}</h3>
            <p>{t('cards.block_3.text')}</p>
            <a href={`${lkBasePath}/registration`}>
              <Button>{t('cards.cta')}</Button>
            </a>
          </div>
        </SwiperSlide>

        <SwiperSlide className={styles.cardsListSliderSlide}>
          <div className={styles.cardsListItem}>
            <GamingIcon />
            <h3>{t('cards.block_4.title')}</h3>
            <p>{t('cards.block_4.text')}</p>
            <a href={`${lkBasePath}/registration`}>
              <Button>{t('cards.cta')}</Button>
            </a>
          </div>
        </SwiperSlide>

        {/*<SwiperSlide className={styles.cardsListSliderSlide}>*/}
        {/*  <div className={styles.cardsListItem}>*/}
        {/*    <FinanceIcon/>*/}
        {/*    <h3>{t('cards.block_5.title')}</h3>*/}
        {/*    <p>{t('cards.block_5.text')}</p>*/}
        {/*    <a href={`${lkBasePath}/registration`}><Button>{t('cards.cta')}</Button></a>*/}
        {/*  </div>*/}
        {/*</SwiperSlide>*/}
      </Swiper>

      <Container maxWidth={'md'} sx={{ margin: '112px auto 56px auto' }} className={styles.paymentSystemList}>
        <Grid container columns={8} sx={{ marginTop: '2rem' }} alignContent="center" alignItems="center">
          <Grid className={styles.paymentSystem} item md={2} xs={4}>
            <VisaIcon />
          </Grid>
          <Grid className={styles.paymentSystem} item md={2} xs={4}>
            <GpayIcon />
          </Grid>
          {/*<Grid className={styles.paymentSystem} item md={2} xs={4}>*/}
          {/*  <BitcoinAltIcon />*/}
          {/*</Grid>*/}
          <Grid className={styles.paymentSystem} item md={2} xs={4}>
            <ApplePayAltIcon />
          </Grid>
          <Grid className={styles.paymentSystem} item md={2} xs={4}>
            <MastercardAltIcon />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
