import type { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Cards } from '../components/Cards';
import { Hero } from '../components/Hero';
import { Info } from '../components/Info';
import { Options } from '../components/Options';

const Page: NextPage = () => {
  return (
    <>
      <Hero />
      <Options />
      <Cards />
      <Info />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
    },
  };
};

export default Page;
