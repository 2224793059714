import { Box, Container, Grid, Button } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';

import styles from './Info.module.scss';

export const Info: FC = () => {
  const { t } = useTranslation('common');

  return (
    <Box className={styles.infoWrapper}>
      <Container maxWidth={'md'} sx={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <div className={styles.infoTitle}>
            <Trans i18nKey="info.title">
              Full payment <span>Stack</span>.
            </Trans>
          </div>
          <div className={styles.infoSubtitle}>{t('info.subtitle')}</div>
          <Grid container spacing={4} columns={8} sx={{ marginTop: '2rem' }}>
            <Grid item md={2} className={styles.infoSection}>
              <h4>120+</h4>
              <p>{t('info.paymentMethods')}</p>
            </Grid>
            <Grid item md={2} className={styles.infoSection}>
              <h4>60+</h4>
              <p>{t('info.geos')}</p>
            </Grid>
          </Grid>
          <Grid container spacing={4} columns={8} sx={{ marginTop: '2rem' }}>
            <Grid item md={2} className={styles.infoSection}>
              <h4>159+</h4>
              <p>{t('info.currencies')}</p>
            </Grid>
            <Grid item md={2} className={styles.infoSection}>
              <h4>24/7</h4>
              <p>{t('info.support')}</p>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};
